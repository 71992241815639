// exports.api_host = "http://localhost:3200";
// exports.api_host = "http://198.211.114.84:3200";
exports.api_host = "https://api.guiasanofimx.com";

exports.post_request = (vm, resource, data) => {
  let c = this;
  return new Promise((resolve, reject) => {
    let rsrce = `${c.api_host}${resource}`;

    let token = vm.$session.get("token");
    let headers = null;
    if (token) {
      headers = {
        headers: {
          "x-access-token": token,
        },
      };
    }

    vm.axios
      .post(rsrce, data, headers)
      .then((r) => {
        if (r.status != 200) reject(r.error);
        else {
          let rr = r.data;
          if (!rr.success) {
            console.log(rr);
            if (rr.error == "SESSION_EXPIRED") {
              vm.$session.destroy();
              vm.$router.replace({ name: "Root" });
            } else {
              reject(rr.error);
            }
          } else resolve(rr.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

exports.error_toast = (vm, message) => {
  vm.$toasted.show(message, {
    theme: "bubble",
  });
};

exports.init_datable = (reference, data, columns, onSelect) => {
  if (window.jQuery.fn.DataTable.isDataTable(reference)) {
    window.jQuery(reference).DataTable().destroy();
  }

  window.jQuery(reference).find("tbody").empty();

  let tbl = window.jQuery(reference).DataTable({
    autoWidth: true,
    ordering: true,
    scrollCollapse: true,
    data: data,
    columns: columns,
    createdRow: function (row, data) {
      window.jQuery(row).click(function () {
        if (typeof onSelect == "function") {
          onSelect(data);
        }
      });
    },
  });
  return tbl;
};
